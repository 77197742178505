import { manager } from './modules/manager.js';

import { support } from './modules/support.js';
import { template } from './modules/template.js';
import { form } from './modules/form.js';

import { account } from './account.js';
import { list } from './list.js';

import { bookmarklet } from './bookmarklet.js';


if('serviceWorker' in navigator) {

	/*navigator.serviceWorker.getRegistrations()
	.then(registrations => {
    	for(let registration of registrations) {
        	registration.unregister();
    	}
	});*/

	navigator.serviceWorker.register('/service-worker.js', { scope: '/' })
	.then(reg => {
		if(reg.installing) { console.log('Service worker installing'); }
		else if(reg.waiting) { console.log('Service worker installed'); }
		else if(reg.active) { console.log('Service worker active'); }
	}).catch(function(error) {
		// registration failed
		console.log(`Registration failed with ${error}`);
	});
}


// register main app
manager.registerApp('qdb');

// register modules
manager.registerModule('support', support);
manager.registerModule('template', template);
manager.registerModule('form', form);

// register specific page script
manager.registerModule('account', account, { pattern: '/mon-compte' });
manager.registerModule('list', list, { pattern: '/liste/(.*?)', expose: true }, () => list.loaded());

manager.registerModule('bookmarklet', bookmarklet, { pattern: '/bookmarklet/(.*?)' });

import { template } from './modules/template.js';
import { form } from './modules/form.js';
import { notify } from './components/notification.js';
import { eventHandler } from './modules/eventHandler.js';
import { addToCookie, isUrl, fetchRequest, getCookie } from './modules/helpers.js';

import { cookie_lists, cookie_utok } from './config.js';

export class List {

	// ==============================================================================================================================================
	// constructor
	// ==============================================================================================================================================

	constructor() {

		const content = document.querySelector('.page .content');
		if(content != null) {
			this.list = content.getAttribute('data-list');
		}

		this.requestedItem = {};
	}

	// ==============================================================================================================================================
	// exec function
	// ==============================================================================================================================================

	run() {
		// preloading ajax request with list slug
		template.preload(data => { return { list: this.list }; });
		form.preload(data => { return { list: this.list }; });

		// handle requesting item
		this.handleRequest();

		// handle item operations
		this.handleList();

		// handle item operations
		this.handleItem();
	}

	loaded() {

		const parsedUrl = new URL(window.location);
  		if(parsedUrl.searchParams.get('item-url')) {
  			const magicBox = document.querySelector('[data-role=magic-box]');
  			magicBox.querySelector('input[name=magic-box]').value = parsedUrl.searchParams.get('item-url');
  			magicBox.querySelector('button[name=submit-request]').click();
  		}
	}

	// ==============================================================================================================================================
	// request
	// ==============================================================================================================================================

	handleRequest() {

		template.preload('request-details', data => {

			const item = document.querySelector('.magic-box .product');
			if(item != null) {
				item.remove();
			}

			return { list: this.list };
		});

		form.preload('request', request => {
			request.form.querySelectorAll('.field--error').forEach(field => field.classList.remove('field--error'));
		});

		// once request is done, display request details
		form.done('request', request => {

			this.requestedItem = {};
			if(request.response.success == true) {

				const submitRequest = document.querySelector('[data-role=submit-request]');
				submitRequest.classList.remove('product-add-button--on');
				submitRequest.classList.add('product-add-button--off');

				//eventHandler.setElementListener('animationend', 'request', e => {
				//	if(e.animationName == 'product-add-button--off') {
						this.requestedItem = request.response.data;

						template.get({
							url: 'request/details',
							target: document.querySelector('[data-role=magic-box]'),
							where: 'append',
							key: 'request-details',
							preload: true,
							params: request.response.data
						});
				//	}
				//}, submitRequest);
			}
		});

		// once request details is displayed, get request images and display them
		template.done('request-details', request => {

			//if(this.requestedItem.forbidden) { return; }

			if(this.requestedItem.url == null || this.requestedItem.image) {
				this.handleRequestImages();
				return;
			}

			document.querySelector('[data-role=request-images]').classList.add('is-loading');

			fetchRequest({
				url: '/api/request/images',
				data: { 'magic-box': this.requestedItem.url }
			},
			response => {

				if(response.success == true) {

					if(response.data.image) {
						this.requestedItem.image = response.data.image;
					}

					if(response.data.images) {
						this.requestedItem.images = response.data.images;
					}

					template.get({
						url: 'request/images',
						target: document.querySelector('[data-role=request-images]'),
						key: 'request-images',
						params: Object.assign({}, { list: this.list }, response.data)
					});
				}
			})
			.then(() => {
				document.querySelector('[data-role=request-images]').classList.remove('is-loading');
			});
		});

		// once images are displayed, handle multiple images and file upload
		template.done('request-images', request => {
			this.handleRequestImages();
		});

		// handle request cancelling, reset request form
		template.removed('cancel-request', data => {

			data.element.classList.remove('request-details--on');
			data.element.classList.add('request-details--off');

			data.element.addEventListener('animationend', (e) => {

				const submitRequest = document.querySelector('[data-role=submit-request]');
				submitRequest.classList.remove('product-add-button--off');
				submitRequest.classList.add('product-add-button--on');

				document.querySelector('form[name=request]').classList.remove('was-validated');
				document.querySelector('input[name=magic-box]').value = "";
				data.element.remove();
			});
		});
	}

	handleRequestImages() {

		const container = document.querySelector('.product-image-container--editable');
		if(container != null) {
			container.classList.remove('product-image-container--default');
			container.classList.add('product-image-container--resize');
		}

		const currentImage = document.querySelector('[data-role=request-images] [data-role=request-image]');

		const next = document.querySelector('[data-role=request-images] [data-role=next-image]');
		if(next != null) {
			next.addEventListener('click', e => {

				e.preventDefault();
				let index = this.requestedItem.images.indexOf(currentImage.src.replace('+', '%2B')) + 1;
				if(index >= this.requestedItem.images.length) {
					index = 0;
				}
				currentImage.src = this.requestedItem.images[index];
				document.querySelector('[data-role=request-images] input[name=image]').value = currentImage.src;
			});
		}


		const previous = document.querySelector('[data-role=request-images] [data-role=previous-image]');
		if(previous != null) {
			previous.addEventListener('click', e => {
				e.preventDefault();

				let index = this.requestedItem.images.indexOf(currentImage.src) - 1;
				if(index < 0) {
					index = this.requestedItem.images.length - 1;
				}
				currentImage.src = this.requestedItem.images[index];
				document.querySelector('[data-role=request-images] input[name=image]').value = currentImage.src;
			});
		}

		this.handleFileUpload(document.querySelector('[data-role=request-images] input[name=image-upload]'), result => {
			this.requestedItem.image = result;
		});
	}

	// ==============================================================================================================================================
	// list
	// ==============================================================================================================================================

	handleList() {

		// prevent share list url from re-render template
		template.preload('list-share-url', data => {

			if(document.querySelector('.share-url-popin') != null) {
				return { abort: true };
			}

			return { list: this.list };
		});

		template.removed('remove-share-url-popin', request => {

			request.element.classList.remove('share-url-popin--in');
			request.element.classList.add('share-url-popin--out');

			eventHandler.setElementListener('animationend', 'remove-share-url-popin', e => {
				request.element.remove();
			}, request.element);
		});

		// handle list details update
		form.done('list-details', request => {

			if(request.response.success == true) {

				this.handleNewList(request.response.data.list);

				template.get({
					url: 'list/details',
					target: request.form.closest('[data-role=template-placeholder]'),
					params: { list: this.list }
				});
			}
		});

		const items = document.querySelector('[data-role=items]');
		// list filtering
		const filterFlaggedItems = document.querySelector('[data-role=flagged-items]');
		if(filterFlaggedItems != null) {

			eventHandler.setElementListener('change', 'filter',  e => {

				filterFlaggedItems.closest('div').classList.add('items--filtering');

				template.get({
					url: 'list/items',
					target: document.querySelector('[data-role=items]'),
					key: 'filter-items',
					preload: true,
					params: { sort: document.querySelector('[data-role=sort]').value, 'flagged-items': !e.target.checked }
				});
			}, filterFlaggedItems);

			template.done('filter-items', e => {
				filterFlaggedItems.closest('div').classList.remove('items--filtering');
			});
		}

		// list sorting
		const sortOrder = document.querySelector('[data-role=sort]');
		if(sortOrder != null) {

			eventHandler.setElementListener('change', 'sort', e => {

				sortOrder.closest('div').classList.add('items--filtering');

				template.get({
					url: 'list/items',
					target: document.querySelector('[data-role=items]'),
					key: 'sort-items',
					preload: true,
					params: { sort: e.target.value, 'flagged-items': !document.querySelector('[data-role=flagged-items]').checked }
				});

				template.done('sort-items', e => {
					sortOrder.closest('div').classList.remove('items--filtering');
				});

			}, sortOrder);
		}
	}

	// ==============================================================================================================================================
	// item
	// ==============================================================================================================================================

	handleItem() {

		// ITEM CREATE

		// preload data for item create request
		form.preload('create-item', data => {

			let term = document.querySelector('input[name=magic-box]').value;

			if(isUrl(term)) {
				return { list: this.list, url: term };
			}
			else {
				return { list: this.list, title: term };
			}
		});

		// once item is created, handle new list and reset requeset form
		form.done('create-item', request => {

			this.handleNewList(request.response.data.list);

			document.querySelector('form[name=request]').classList.remove('was-validated');
			document.querySelector('input[name=magic-box]').value = "";
			document.querySelector('[data-role=submit-request]').style.display = "block";
			document.querySelector('[data-role=request-details]').remove();

			const submitRequest = document.querySelector('[data-role=submit-request]');
			submitRequest.classList.remove('product-add-button--off');
			submitRequest.classList.add('product-add-button--on');

			template.get({
				url: 'list/item',
				target: document.querySelector('[data-role=items]'),
				where: 'prepend',
				params: { list: this.list, item: request.response.data.item, 'item-appear': true }
			});
		});


		// ITEM DELETE

		template.preload('item-delete', request => {

			return { list: this.list, item: request.data.element.closest('li').getAttribute('data-item') };
		});

		// set item token in delete form hidden field
		template.done('item-delete', request => {

			if(request.element) {
				const item = request.element.closest('li');
				if(item != null) {
					item.querySelector('form[name="delete-item"] input[name="item"]').value = item.getAttribute('data-item');
				}
			}
		});

		// cancel delete animation
		template.removed('cancel-delete-item', request => {

			if(request.element) {

				request.element.querySelector('.product > .product-overlay').classList.remove('product-overlay--on');
				request.element.querySelector('.product > .product-overlay').classList.add('product-overlay--off');

				request.element.querySelector('.product > .product-popin').classList.remove('product-popin--on');
				request.element.querySelector('.product > .product-popin').classList.add('product-popin--off');

				request.element.querySelector('.product > .product-popin').addEventListener('animationend', (e) => {
					e.target.remove();
					request.element.querySelector('.product > .product-overlay').remove();
				});
			}
		});

		// once item is deleted through API, remove it from DOM
		form.done('delete-item', request => {

			if(request.response.success == true) {

				const li = request.form.closest('li');

				li.classList.add('product--out');
				li.addEventListener('animationend', () => li.remove());
			}
		});


		// ITEM UPDATE

		// add flip animation before load update item template (item view -> click on update item)
		template.preload('item-update', request => {

			const li = request.data.element.closest('li');
			li.querySelector('.product-container').classList.remove('product--unflip');
			li.querySelector('.product-container').classList.add('product--flip');

			return { list: this.list, item: li.getAttribute('data-item') };
		});

		// unflag button specific behavior and handle fileupload
		template.done('item-update', request => {

			this.handleFileUpload(request.target.querySelector('input[name=image-upload]'), result => {
				console.log(result);
			});

			const unflag = request.target.querySelector('button[name=submit-unflag-item]');
			if(unflag != null) {
				unflag.addEventListener('click', e => {

					e.preventDefault();

					fetchRequest({
						url: '/api/item',
						method: 'put',
						data: { user: getCookie(cookie_utok), list: this.list, item: request.target.closest('li').getAttribute('data-item'), flagged: false }
					},
					response => {
						if(response.success == true) {

							request.target.closest('li').querySelector('.product-container').classList.remove('product--flip');
							request.target.closest('li').querySelector('.product-container').classList.add('product--unflip');
							template.get({
								url: 'item',
								target: request.target.closest('[data-role=template-placeholder]'),
								params: { list: this.list, item: response.data.item }
							});
						}
					});
				});
			}
		});

		// add flip animation before load item template (item update view -> click on cancel update item)
		template.preload('item', request => {

			const li = request.data.element.closest('li');
			li.querySelector('.product-container').classList.remove('product--flip');
			li.querySelector('.product-container').classList.add('product--unflip');

			return { item: li.getAttribute('data-item') };
		});

		// set item and list keys to request data
		form.preload('update-item', request => {

			return { list: this.list, item: request.form.closest('li').getAttribute('data-item') };
		});

		// once update item is done, render item show
		form.done('update-item', request => {

			if(request.response.success == true) {

				const li = request.form.closest('li');
				li.querySelector('.product-container').classList.remove('product--flip');
				li.querySelector('.product-container').classList.add('product--unflip');

				template.get({
					url: 'item',
					target: request.form.closest('[data-role=template-placeholder]'),
					params: { list: this.list, item: request.response.data.item }
				});
			}
		});


		// ITEM FLAG

		// preload item data before flag request
		template.preload('item-flag', request => {

			return { list: this.list, item: request.data.element.closest('li').getAttribute('data-item') };
		});

		template.done('item-flag', request => {

			eventHandler.setElementListener(
				'animationend',
				'product-popin--on',
				e => request.target.querySelector('input[name=flag-note]').focus(),
				request.target.querySelector('.product-popin--on')
			);
		});

		// cancel flag animation
		template.removed('cancel-flag-item', request => {

			if(request.element) {

				request.element.querySelector('.product > .product-overlay').classList.remove('product-overlay--on');
				request.element.querySelector('.product > .product-overlay').classList.add('product-overlay--off');

				request.element.querySelector('.product > .product-popin').classList.remove('product-popin--on');
				request.element.querySelector('.product > .product-popin').classList.add('product-popin--off');

				request.element.querySelector('.product > .product-popin').addEventListener('animationend', (e) => {
					e.target.remove();
					request.element.querySelector('.product > .product-overlay').remove();
				});
			}
		});

		// set item and list keys to request data
		form.preload('flag-item', request => {

			return { list: this.list, item: request.form.closest('li').getAttribute('data-item'), flagged: true };
		});

		// once update item is done, render item show
		form.done('flag-item', request => {

			if(request.response.success == true) {
				template.get({
					url: 'item',
					target: request.form.closest('[data-role=template-placeholder]'),
					params: { list: this.list, item: request.response.data.item, flag: request.response.data.flag, 'flag-appear': true }
				});
			}
		});
	}

	// ==============================================================================================================================================
	// helpers
	// ==============================================================================================================================================

	handleNewList(list) {

		// changer l'url
		if(this.list != list) {
			this.list = list;

			// écriture cookie
			addToCookie(cookie_lists, this.list);

			// changement de l'url
			history.pushState({}, 'qdb', `${this.list}`);


			// affichage du partage
			template.get({
				url: 'list/share',
				target: document.querySelector('[data-role=list-details]'),
				where: 'append',
				params: { list: this.list }
			});

			// affichage de la légende
			template.get({
				url: 'request/legend',
				target: document.querySelector('[data-role=request]'),
				where: 'append',
				params: { list: this.list }
			});
		}
	}

	handleFileUpload(element, callback) {

		element.addEventListener('change', e => {

			this.treatUploadedFile(element.form, e.target.files[0], response => {

				element.form.querySelector('[data-role=request-image]').src = response.result;
				element.form.querySelector('input[name=image]').value = response.result;
				callback(response.result);
			});
		});

		const dropzone = element.form.querySelector('[data-action=dropzone]');
		if(dropzone) {
			eventHandler.setMultipleListener(dropzone, 'drag dragstart dragend dragover dragenter dragleave drop', e => { e.preventDefault(); });
			eventHandler.setMultipleListener(dropzone, 'dragover dragenter', () => { dropzone.classList.add('product-image-dropzone--active'); });
			eventHandler.setMultipleListener(dropzone, 'dragleave dragend drop', () => { dropzone.classList.remove('product-image-dropzone--active'); });
			dropzone.addEventListener('drop', e => {


				this.treatUploadedFile(element.form, e.dataTransfer.files[0], response => {
					element.form.querySelector('[data-role=request-image]').src = response.result;
					element.form.querySelector('input[name=image]').value = response.result;
					callback(response.result);
				});
			});
		}
	}

	treatUploadedFile(formElement, file, callback) {

		const container = formElement.querySelector('[data-role=request-images]');
		container.classList.remove('field-error');
		let error = container.querySelector('span.error');
		if(error) { error.remove(); }

		let errorMessage = null;

		if(!['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'image/webp'].includes(file.type)) {
			errorMessage = 'Veuillez choisir un fichier de type image.';
		}
		else if(file.size > 52428800) {
			errorMessage = 'Veuillez choisir un fichier moins volumineux (< 50 Mo).';
		}


		if(errorMessage != null) {
			container.classList.add('field--error');
			notify({
				target: container,
				where: 'append',
				tag: 'span',
				className: 'error',
				content: errorMessage
			});
			return;
		}


		const reader = new FileReader();
    	reader.onloadend = () => {

			callback({ result: reader.result });
    	}
    	reader.readAsDataURL(file);
	}
}

export const list = new List();
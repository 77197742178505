import { eventHandler } from './eventHandler.js';

export class Manager {

	// ==============================================================================================================================================
	// constructor
	// ==============================================================================================================================================

	constructor() {

		this.appName = null;
	}

	// ==============================================================================================================================================
	// methods
	// ==============================================================================================================================================

	registerApp(appName, callback = null) {

		this.appName = appName;

		document.addEventListener("DOMContentLoaded", () => {

			window[appName] = window[appName] || {};
			if(callback != null) { callback(); }
		});
	}

	registerModule(name, module, options = {}, callback = null) {

		if(this.appName == null) {
			throw new Error('registerModule: registerApp first');
		}

		let defaults = {
			pattern: null,
			expose: false
		};

		options = Object.assign({}, defaults, options);
		if(options.pattern) {
			const regex = new RegExp(`^${options.pattern}$`);
			if(document.location.pathname.match(regex) == null) {
				return false;
			}
		}

		document.addEventListener("DOMContentLoaded", () => {

			if(options.expose == true) {
				window[this.appName][name] = module;
			}
			if(typeof module.run === 'function') {
				eventHandler.bootstrapEvents(() => { module.run(); });
			}
			if(callback != null) { callback(); }
		});
	}
}

export const manager = new Manager();
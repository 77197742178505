import { template } from './modules/template.js';
import { form } from './modules/form.js';
import { eventHandler } from './modules/eventHandler.js';
import { fetchRequest } from './modules/helpers.js';


export class Bookmarklet {

	// ==============================================================================================================================================
	// constructor
	// ==============================================================================================================================================

	run() {

		// load iframe
		window.parent.postMessage('iframeQDBLoaded', '*');
		document.querySelectorAll('[data-action=close-bookmarklet]').forEach(element => {

			element.addEventListener('click', e => {
				e.preventDefault();
				window.parent.postMessage('closeIframeQDB', '*');
			});
		});

		document.querySelectorAll('[data-role=vendor-login]').forEach(element => {
			element.addEventListener('click', e => {
				window.addEventListener('visibilitychange', e => {
					if(document.visibilityState == 'visible') {
						location.reload();
					}
				});
			});
		});


		this.handleItem();
	}

	handleItem() {

		if(document.querySelector('.bookmarklet--product') != null && !this.loaded) {

			const params = new URLSearchParams(window.location.search);
			const list = document.querySelector('[data-list]').getAttribute('data-list');

			this.requestedItem = {};
			document.querySelector('[data-role=request-images]').classList.add('is-loading');

			fetchRequest({
				url: '/api/request/images',
				data: { 'magic-box': params.get('url') }
			},
			response => {

				if(response.success == true) {

					if(response.data.image) {
						this.requestedItem.image = response.data.image;
					}

					if(response.data.images) {
						this.requestedItem.images = response.data.images;
					}

					template.get({
						url: 'request/images',
						target: document.querySelector('[data-role=request-images]'),
						key: 'request-images',
						params: Object.assign({}, { list: list }, response.data)
					});
				}
			})
			.then(() => {
				this.loaded = true;
				document.querySelector('[data-role=request-images]').classList.remove('is-loading');
			});


			template.done('request-images', request => {

				const container = document.querySelector('.product-image-container--editable');
				if(container != null) {
					container.classList.remove('product-image-container--default');
					container.classList.add('product-image-container--resize');
				}

				const currentImage = document.querySelector('[data-role=request-images] [data-role=request-image]');

				const next = document.querySelector('[data-role=request-images] [data-role=next-image]');
				if(next != null) {
					next.addEventListener('click', e => {

						e.preventDefault();
						let index = this.requestedItem.images.indexOf(currentImage.src) + 1;
						if(index >= this.requestedItem.images.length) {
							index = 0;
						}
						currentImage.src = this.requestedItem.images[index];
						document.querySelector('[data-role=request-images] input[name=image]').value = currentImage.src;
					});
				}


				const previous = document.querySelector('[data-role=request-images] [data-role=previous-image]');
				if(previous != null) {
					previous.addEventListener('click', e => {
						e.preventDefault();

						let index = this.requestedItem.images.indexOf(currentImage.src) - 1;
						if(index < 0) {
							index = this.requestedItem.images.length - 1;
						}
						currentImage.src = this.requestedItem.images[index];
						document.querySelector('[data-role=request-images] input[name=image]').value = currentImage.src;
					});
				}

				this.handleFileUpload(document.querySelector('[data-role=request-images] input[name=image-upload]'), result => {
					this.requestedItem.image = result;
				});
			});
		}
	}

	handleFileUpload(element, callback) {

		element.addEventListener('change', e => {

			this.treatUploadedFile(element.form, e.target.files[0], response => {

				element.form.querySelector('[data-role=request-image]').src = response.result;
				element.form.querySelector('input[name=image]').value = response.result;
				callback(response.result);
			});
		});

		const dropzone = element.form.querySelector('[data-action=dropzone]');
		if(dropzone) {
			eventHandler.setMultipleListener(dropzone, 'drag dragstart dragend dragover dragenter dragleave drop', e => { e.preventDefault(); });
			eventHandler.setMultipleListener(dropzone, 'dragover dragenter', () => { dropzone.classList.add('product-image-dropzone--active'); });
			eventHandler.setMultipleListener(dropzone, 'dragleave dragend drop', () => { dropzone.classList.remove('product-image-dropzone--active'); });
			dropzone.addEventListener('drop', e => {


				this.treatUploadedFile(element.form, e.dataTransfer.files[0], response => {
					element.form.querySelector('[data-role=request-image]').src = response.result;
					element.form.querySelector('input[name=image]').value = response.result;
					callback(response.result);
				});
			});
		}
	}

	treatUploadedFile(formElement, file, callback) {

		const container = formElement.querySelector('[data-role=request-images]');
		container.classList.remove('field-error');
		let error = container.querySelector('span.error');
		if(error) { error.remove(); }

		let errorMessage = null;

		if(!['image/jpg', 'image/jpeg', 'image/gif', 'image/png', 'image/webp'].includes(file.type)) {
			errorMessage = 'Veuillez choisir un fichier de type image.';
		}
		else if(file.size > 52428800) {
			errorMessage = 'Veuillez choisir un fichier moins volumineux (< 50 Mo).';
		}


		if(errorMessage != null) {
			container.classList.add('field--error');
			notify({
				target: container,
				where: 'append',
				tag: 'span',
				className: 'error',
				content: 'Veuillez choisir un fichier de type image.'
			});
			return;
		}


		const reader = new FileReader();
    	reader.onloadend = () => {

			callback({ result: reader.result });
    	}
    	reader.readAsDataURL(file);
	}
}

export const bookmarklet = new Bookmarklet();
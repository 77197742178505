export class EventHandler {

	// ==============================================================================================================================================
	// Events bootstrap / refresh
	// ==============================================================================================================================================

	bootstrapEvents(handler) {

		document.addEventListener('refresh-events', e => handler());
		handler();
	}

	refreshEvents() {

		this.dispatch('refresh-events');
	}

	// ==============================================================================================================================================
	// Event dispacther
	// ==============================================================================================================================================

	dispatch(event, key = null, data = null, element = document) {

		element.dispatchEvent(new CustomEvent(event, { detail: { key: key, data: data } }));
	}

	// ==============================================================================================================================================
	// Event listener
	// ==============================================================================================================================================

	hasEventListener(eventName, key, element = document) {

		if(!element.events) {
			return false;
		}

		return element.events.findIndex(e => e.key === key && e.event === eventName) > 0;
	}

	setElementListener(eventName, key, listener, element) {

		key = key != null ? key : eventName;

		if(!element.events) {
			element.events = [];
		}
		else {
			let events = element.events.filter(e => e.key === key && e.event === eventName);
			if(events.length > 0) {
				events.forEach(e => element.removeEventListener(e.event, e.listener));
			}
		}

		let existingEventIndex = element.events.findIndex(e => e.key === key && e.event === eventName);
		if(existingEventIndex >= 0) {
			element.events[existingEventIndex] = { key, event: eventName, listener };
		}
		else {
			element.events.push({ key, event: eventName, listener });
		}
		element.addEventListener(eventName, listener);
	}

	removeElementListener(eventName, key, element = document) {

		if(!element.events) {
			return false;
		}

		let events = element.events.filter(e => e.key === key && e.event === eventName);
		if(events.length > 0) {
			events.forEach(e => element.removeEventListener(e.event, e.listener));
		}
	}

	setElementsListener(event, key, selector, listener) {


		document.querySelectorAll(selector).forEach(element => this.setElementListener(event, key, listener, element));
	}

	setCustomListener(event, key, listener) {

		this.setElementListener(event, key, listener, document);
	}

	setClickListener(key, element, listener) {

		this.setElementListener('click', key, listener, element);
	}

	setMultipleListener(element, events, listener) {

		events.split(' ').forEach(event => element.addEventListener(event, listener));
	}
}

export const eventHandler = new EventHandler();
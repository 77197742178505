import { eventHandler } from './eventHandler.js';
export class Support {

	// ==============================================================================================================================================
	// exec function
	// ==============================================================================================================================================

	run() {
		// Image Toggler
		eventHandler.setElementsListener('click', 'image-toggler', '[data-action=image-toggler]', e => this.handleToggleImage(e));

		// Loading Button
		eventHandler.setElementsListener('click', 'loading', '[data-loading]', e => this.addLoader(e.currentTarget));

		// Show / Hide password
		eventHandler.setElementsListener('click', 'show-password', '[data-action=show-password]', e => this.handleShowPassword(e.currentTarget));

		// copy list url
		eventHandler.setElementsListener('click', 'copy', '[data-action=copy]', e => this.handleCopy(e.currentTarget));

		// handleShowHide
		eventHandler.setElementsListener('click', 'show-hide', '[data-action=show-hide]', e => this.handleShowHide(e.currentTarget));

		// Lazy Loading
		//eventHandler.setCustomListener('DOMContentLoaded', 'lazyload', e => this.handleLazyloading(e.currentTarget));
		this.handleLazyloading(document);

		// Notification dismiss
		eventHandler.setElementsListener('click', 'notification-dismiss', '[data-action=notification-dismiss]', e => this.handleNotificationDismiss(e.currentTarget));

		// Sortable items
		this.handleSortableItems(document.querySelector('[data-role=items]'));
	}

	// ==============================================================================================================================================
	// handlers
	// ==============================================================================================================================================

	handleToggleImage(e) {

		e.preventDefault();

		let target = document.querySelector(`[data-role=${e.currentTarget.getAttribute('data-target')}]`);
		let currentSrc = target.getAttribute('src');
		let currentClass = e.currentTarget.getAttribute('class');

		target.setAttribute('src', e.currentTarget.getAttribute('data-src'));
		e.currentTarget.setAttribute('class', e.currentTarget.getAttribute('data-class'));
		e.currentTarget.setAttribute('data-src', currentSrc);
		e.currentTarget.setAttribute('data-class', currentClass);
	}

	handleShowPassword(element) {

		if(element.form) {
			let input = element.form.querySelector(`[name=${element.getAttribute('data-target')}]`);
			input.type = input.type == 'text' ? 'password' : 'text';
		}
	}

	handleShowHide(element) {

		if(element.form) {
			element.form.querySelector(`[data-role=${element.getAttribute('data-target')}]`).classList.toggle(element.getAttribute('data-class'));
		}
	}

	handleCopy(element) {

		const input = document.querySelector(`[data-role="${element.getAttribute('data-target')}"]`);

		if(navigator.userAgent.match(/ipad|ipod|iphone/i)) {

			let oldContentEditable = input.contentEditable;
			let oldReadOnly = input.readOnly;
			let range = document.createRange();

			input.contentEditable = true;
			input.readOnly = false;
			range.selectNodeContents(input);

			var selection = window.getSelection();
			selection.removeAllRanges();
			selection.addRange(range);

			input.setSelectionRange(0, input.value.length);

			input.contentEditable = oldContentEditable;
			input.readOnly = oldReadOnly;
		}
		else {
			input.select();
		}

		document.execCommand('copy');
	}

	handleLazyloading(element) {

		if('loading' in HTMLImageElement.prototype) {

			let lazyImages = [].slice.call(document.querySelectorAll("[data-lazyload]"));
			let active = false;

			const lazyLoad = function() {
			    if (active === false) {
					active = true;

			      	setTimeout(function() {
			        	lazyImages.forEach(function(lazyImage) {
			          		if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== "none") {
			            		lazyImage.src = lazyImage.dataset.src;
					            lazyImage.srcset = lazyImage.dataset.srcset;
					            lazyImage.removeAttribute("data-lazyload");

					            lazyImages = lazyImages.filter(function(image) {
					              return image !== lazyImage;
					            });

					            if (lazyImages.length === 0) {
									document.removeEventListener("scroll", lazyLoad);
									window.removeEventListener("resize", lazyLoad);
									window.removeEventListener("orientationchange", lazyLoad);
					            }
			          		}
			        	});

			        	active = false;
			      	}, 200);
		    	}
			};

			document.addEventListener("scroll", lazyLoad);
			window.addEventListener("resize", lazyLoad);
			window.addEventListener("orientationchange", lazyLoad);
		}
	}

	addLoader(element) {

		element.classList.add('button--loading');

		if(element.getAttribute('data-spinner-color') != undefined) {
			element.classList.add(`spinner--${element.getAttribute('data-spinner-color')}`);
		}
		else {
			if(element.tagName.toLowerCase() == 'button') {
				element.classList.add(`spinner--white`);
			}
			else {
				element.classList.add(`spinner--pink`);
			}
		}
	}

	removeLoader(element) {
		if(element.tagName.toLowerCase() == 'form') {

			let button = element.querySelector('.button--loading');
			if(button != null) {
				button.classList.remove('button--loading');
			}
		}
		else {
			element.classList.remove('button--loading');
		}
	}

	handleNotificationDismiss(element) {

		const container = element.closest('div');
		container.classList.add('notification--dismiss');

		eventHandler.setElementListener('animationend', 'notification-dismiss', e => {
			e.currentTarget.remove();
		}, container);
	}

	handleSortableItems(element) {

		//console.log(element);

	}
}

export const support = new Support();
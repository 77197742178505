import { getCookie, fetchRequest } from './helpers.js';
import { eventHandler } from './eventHandler.js';
import { support } from './support.js';
import { notify } from '../components/notification.js';

import { cookie_utok, async_enabled } from '../config.js';

export class Form {

	constructor() {

		this.options = {
			notificationTarget: '.page .content'
		};
	}

	// ==============================================================================================================================================
	// exec function
	// ==============================================================================================================================================

	run() {
		if(async_enabled == true) {

			eventHandler.setElementsListener('submit', 'form--validate', 'form', e => this.handleValidate(e));
			eventHandler.setElementsListener('form--validated', 'form--validated', 'form', e => this.handleFormSubmit(e));
		}
	}

	// ==============================================================================================================================================
	// handlers
	// ==============================================================================================================================================

	handleValidate(e) {

		const form = e.currentTarget;

		if(form.hasAttribute('data-novalidate')) {
			eventHandler.dispatch('form--validated', `form--${form.name}`, { event: e }, form);
		}
		else {
			if(form.checkValidity() === false) {
				e.preventDefault();
				e.stopPropagation();

				support.removeLoader(form);
			}
			else {
				eventHandler.dispatch('form--validated', `form--${form.name}`, { event: e }, form);
			}

			form.classList.add('was-validated');
		}
	}

	handleFormSubmit(e) {

		if(e.detail && e instanceof CustomEvent && e.detail.data.event.target.hasAttribute('data-api')) {

			e.preventDefault();
			e.stopPropagation();
			e.detail.data.event.preventDefault();
			e.detail.data.event.stopPropagation();

			const form = e.detail.data.event.target;

			if(form.hasAttribute('data-preload')) {

				eventHandler.setElementListener('form--preloaded', `form--${form.name}`, e => this.submit(form, e.detail.data), form);
				eventHandler.dispatch('form--preload', `form--${form.name}`, { form }, form);
			}
			else {
				this.submit(form);
			}
		}
	}

	// ==============================================================================================================================================
	// methods
	// ==============================================================================================================================================

	submit(form, params = null) {

		return new Promise(resolve => {

			const submitButton = form.querySelector('button[type="submit"]');
	
			if(submitButton != null) {
				support.addLoader(submitButton);
				submitButton.setAttribute('disabled', 'disabled');
			}
	
			const method = form.getAttribute('data-method') != null ? form.getAttribute('data-method') : 'post';
			let data = this.buildData(form, params);
	
			const notificationTarget = form.getAttribute('data-notification') != null ? form.getAttribute('data-notification') : this.options.notificationTarget;
	
			fetchRequest({
				method: form.getAttribute('data-method') != null ? form.getAttribute('data-method') : 'post',
				url: `/api/${form.getAttribute('data-api')}`,
				data: data
			},
			response => {

				if(!response.success) {
					this.error(form, response.errors);
					if(response.errors == 'no valid user.') {
						window.location.href = '/deconnexion?f=true'
					}
				}
				else {
					if(response.messages) {
						notify({ content: response.messages, name: form.name, target: notificationTarget });
					}
					eventHandler.dispatch('form--done', `form--${form.name}`, { form, response: response }, form);
				}
			})
			.then(() => {
				if(submitButton != null) {
					submitButton.removeAttribute('disabled');
				}
				support.removeLoader(form);
				resolve()
			});
		})
	}

	error(form, errors) {

		for(let fieldName in errors) {
			let field = form.querySelector(`[name="${fieldName}"]`);
			if(field != null) {
				let fieldContainer = field.closest('.field');
				fieldContainer.classList.add('field--error');
				const errorMessage = fieldContainer.querySelector('.error');
				if(errorMessage != null) {
					errorMessage.innerHTML = errors[fieldName];
				}
			}
		}
	}

	buildData(form, params) {

		let data = [...form.elements]
			.filter((element) => { return element.type != 'submit' })
			.map((element) => {
				if(element.type == 'checkbox') {
					return { name: element.name, value: element.checked }
				}
				else {
					return { name: element.name, value: element.value }
				}
			})
			.reduce((object, item) => (object[item.name] = item.value, object) , {});
		data = Object.assign({}, data, params);
		data = Object.assign({}, data, { user: getCookie(cookie_utok) });

		return data;
	}

	// ==============================================================================================================================================
	// listener
	// ==============================================================================================================================================

	preload(...args) {

		let keys, callback;

		if(typeof args[0] === 'function') {
			keys = [...document.querySelectorAll('form')].map(k => { return k.name });
			callback = args[0];
		}
		else {
			keys = args[0];
			callback = args[1];
		}

		if(!Array.isArray(keys)) { keys = [keys] };

		keys.forEach(key => {

			const form = document.querySelector(`form[name=${key}]`);
			if(form == null) {
				return;
			}

			eventHandler.setElementListener('form--preload', `form--${key}`, e => {

				if((eventHandler.hasEventListener('form--preload', e.detail.key, form) || key != 'form') && `form--${key}` != e.detail.key) { return; }

				const data = callback(e.detail.data);

				eventHandler.dispatch('form--preloaded', e.detail.key, data, document.querySelector(`form[name=${key}]`));
			},
			form);

		});
	}

	done(keys, callback) {

		if(!Array.isArray(keys)) { keys = [keys] };

		keys.forEach(key => {

			const form = document.querySelector(`form[name=${key}]`);
			if(form == null) {
				return;
			}

			eventHandler.setElementListener('form--done', `form--${key}`, e => {

				if(`form--${key}` != e.detail.key) { return; }

			 	callback(e.detail.data);
			},
			form);
		});

	}
}

export const form = new Form();
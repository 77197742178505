export class Notification {

	// ==============================================================================================================================================
	// constructor
	// ==============================================================================================================================================

	constructor(options) {

		let defaults = {
			target: 'body',
			className: 'notification',
			where: 'prepend',
			tag: 'div',
			content: '',
			provider: null
		}

		this.options = Object.assign({}, defaults, options);

		this.clean();
	}

	// ==============================================================================================================================================
	// methods
	// ==============================================================================================================================================

	set(key, value) {

		this.options[key] = value;
	}

	show() {

		const target = this.options.target instanceof Element ? this.options.target : document.querySelector(this.options.target);

		let element = document.createElement(this.options.tag);

		if(this.options.provider != null) {
			element.setAttribute('data-provider', this.options.provider);
		}

		element.classList.add(this.options.className);

		if(Array.isArray(this.options.content)) {

			if(this.options.content.length == 1) {
				element.innerHTML = this.options.content;
			}
			else {
				element.innerHTML = `<li>${this.options.content.join('</li><li>')}</li>`;
			}
		}
		else {
			element.innerHTML = this.options.content;
		}


		switch(this.options.where) {

			case 'append':
				target.appendChild(element);
			break;

			case 'prepend':
				target.insertBefore(element, target.firstChild);
			break;

			default:
				target.appendChild(element);
			break;
		}
	}

	clean() {

		if(this.options.provider != null) {
			let element = document.querySelector(`[data-provider=${this.options.provider}]`);
			if(element != null) { element.remove(); }
		}
		else {
			document.querySelectorAll('.notification').forEach((element) => {
				element.remove();
			});
		}
	}
}

// ==================================================================================================================================================
// helper
// ==================================================================================================================================================

export function notify(options) {

	let notification = new Notification(options);
	notification.show();
}
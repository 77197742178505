import { cookie_expires } from '../config.js';
import { notify } from '../components/notification.js';

import 'whatwg-fetch';

// ==============================================================================================================================================
// cookie
// ==============================================================================================================================================

export function getCookie(name) {

	const v = document.cookie.match(`(^|;) ?${name}=([^;]*)(;|$)`);
    return v ? v[2] : null;
}

export function setCookie(name, value) {

	let d = new Date();
	d.setTime(d.getTime() + (cookie_expires * 24*60*60*1000));

	document.cookie = `${name}=${value};expires=${d.toUTCString()};domain=${document.location.hostname};path=/;secure`;
}

export function addToCookie(name, value, delimiter = ':') {

	let cookie = getCookie(name);
	if(cookie != null) {

		let parts = cookie.split(delimiter);

		if(!parts.includes(value)) {
			parts.push(value);
			setCookie(name, parts.join(delimiter));
		}
	}
	else {
		setCookie(name, value);
	}
}

// ==============================================================================================================================================
// data
// ==============================================================================================================================================

export function isUrl(term) {

	return term.match(/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&\/\/=]*)/i);
}


export function singleLineString(strings) {

	let lines = strings.split(/(?:\r\n|\n|\r)/);
	return lines.map((line) => {
		return line.replace(/^\s+/gm, '');
	}).join(' ').trim();
}

// ==============================================================================================================================================
// ajax request
// ==============================================================================================================================================

export function fetchRequest(options, callback) {

	let defaults = {
		method: "get",
		url: null,
		data: [],
		response: 'json',
		credentials: 'same-origin'
	};

	options = Object.assign({}, defaults, options);

	if(options.url == null) {
		return;
	}

	let promise;

	if(options.method == "get") {

		let querystring = Object.keys(options.data)
			.map(key => `${encodeURIComponent(key)}=${encodeURIComponent(options.data[key])}`)
			.join('&');

		if(querystring != "") {
			querystring = `?${querystring}`;
		}
		promise = fetch(`${options.url}${querystring}`, { credentials: options.credentials });
	}
	else {

		promise = fetch(options.url, {
			method: options.method,
			body: JSON.stringify(options.data),
			credentials: options.credentials
		});
	}


	promise.then(response => {

	 	if(!response.ok) { throw response }

	 	if(options.response == 'json') {
	 		return response.json();
	 	}
	 	else if(options.response == 'text') {
	 		return response.text();
	 	}
	 	else {
	 		return response.text();
	 	}
	})
	.then(data => {

		callback(data);
	})
	.catch((error) => {


		if(options.response == 'json') {
			error.json()
			.then(error => {
				if(error.message) {

					if(error.message == 'no valid user.') {

						window.location.href = '/deconnexion?f=true'
					}
					else {
						notify({ content: error.message, name: 'error', target: '.page .content' });
					}
				}
			});
		}

	});

	return promise;
}

// ==============================================================================================================================================
// dom manipulation
// ==============================================================================================================================================

export function insertBefore(newNode, referenceNode) {

	if(newNode instanceof Element) {
		referenceNode.parentNode.insertBefore(newNode, referenceNode);
	}
	else {
		let node = document.createElement('div');
		node.innerHTML = newNode;
		[...node.childNodes].reverse().forEach(n => referenceNode.parentNode.insertBefore(n, referenceNode));
	}
}
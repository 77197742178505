import { eventHandler } from './eventHandler.js';
import { fetchRequest, singleLineString } from './helpers.js';
import { async_enabled } from '../config.js';

export class Template {

	// ==============================================================================================================================================
	// exec function
	// ==============================================================================================================================================

	run() {
		if(async_enabled == true) {
			eventHandler.setElementsListener('click', 'get-template', '[data-template]', e => this.handleGet(e));
			eventHandler.setElementsListener('click', 'remove-template', '[data-action="remove-template"]', e => this.handleDelete(e));
		}
	}

	// ==============================================================================================================================================
	// handlers
	// ==============================================================================================================================================

	handleGet(e) {

		e.preventDefault();
		const element = e.currentTarget;


		let target = element.hasAttribute('data-target') ? element.getAttribute('data-target') : element.closest('[data-role=template-placeholder]');
		if(target instanceof Element == false) {
			target = element.closest(target);
		}

		let data = {
			element: element,
			key: element.getAttribute('data-template').replace(/\//g, "-"),
			url: element.getAttribute('data-template'),
			target: target,
			where: element.getAttribute('data-where')
		};

		if(element.hasAttribute('data-preload')) {

			eventHandler.setCustomListener('template--preloaded', `template--${data.key}`, e => {

				if(e.detail.key == `template--${data.key}` && e.detail.data != null) {
					data.params = e.detail.data;

					if(!data.params.abort) {
						this.fetch(data);
					}
				}
			});

			eventHandler.dispatch('template--preload', `template--${data.key}`, data);
		}
		else {
			this.fetch(data);
		}
	}

	handleDelete(e) {

		e.preventDefault();

		let element;

		if(e.target.getAttribute('data-target') != null) {
			element = document.querySelector(e.target.getAttribute('data-target'));
		}
		else {
			element = e.target.closest('[data-role="template-placeholder"]');
		}

		if(e.target.getAttribute('data-dispatch') != null) {
			eventHandler.dispatch('template--removed', `template--${e.target.getAttribute('data-key')}`, { element });
		}
		else {
			element.remove();
		}
	}

	// ==============================================================================================================================================
	// methods
	// ==============================================================================================================================================

	get(options) {

		if(options.preload == true) {
			eventHandler.setCustomListener('template--preloaded', `template--${options.key}`, e => {

				if(e.detail.key == `template--${options.key}`) {

					options.params = Object.assign({}, options.params, e.detail.data);
					this.fetch(options);
				}
			});

			eventHandler.dispatch('template--preload', `template--${options.key}`, options);
		}
		else {
			this.fetch(options);
		}
	}

	fetch(options) {

		let defaults = {
			target: document.querySelector('body'),
			where: null,
			url: null,
			params: {}
		}

		options = Object.assign({}, defaults, options);

		fetchRequest({
			url: `/template/${options.url}`,
			data: options.params,
			response: 'text'
		},
		response => {

			if(options.target != null) {

				let wraper;
				switch(options.where) {

					case 'append':
						wraper = document.createElement('div');
						wraper.innerHTML = `${response}`;
						[...wraper.childNodes].forEach(node => options.target.appendChild(node));
					break;

					case 'prepend':
						wraper = document.createElement('div');
						wraper.innerHTML = response;

						if(options.target.firstChild) {
							[...wraper.childNodes].reverse().forEach(node => options.target.insertBefore(node, options.target.firstChild));
						}
						else {
							[...wraper.childNodes].forEach(node => options.target.appendChild(node));
						}
					break;

					default:
						options.target.innerHTML = `${response}`;
					break;
				}

				eventHandler.refreshEvents();

				let responseData = {};
				responseData.target = options.target;
				if(options.element != null) {
					responseData.element = options.element;
				}

				eventHandler.dispatch('template--done', `template--${options.key}`, responseData);
			}
		});
	}

	// ==============================================================================================================================================
	// listener
	// ==============================================================================================================================================

	preload(...args) {

		let keys, callback;

		if(typeof args[0] === 'function') {
			keys = 'template';
			callback = args[0];
		}
		else {
			keys = args[0];
			callback = args[1];
		}

		if(!Array.isArray(keys)) { keys = [keys] };

		keys.forEach(key => {
			eventHandler.setCustomListener('template--preload', `template--${key}`, e => {

				if((eventHandler.hasEventListener('template--preload', e.detail.key) || key != 'template') && `template--${key}` != e.detail.key) { return; }

				const data = callback(e.detail);
				eventHandler.dispatch('template--preloaded', e.detail.key, data);
			});
		});
	}

	done(keys, callback) {

		if(!Array.isArray(keys)) { keys = [keys] };

		keys.forEach(key => {
			eventHandler.setCustomListener('template--done', `template--${key}`, e => {

				if(`template--${key}` != e.detail.key) { return; }

				callback({ element: e.detail.data.element, target: e.detail.data.target });
			});
		});
	}

	removed(keys, callback) {

		if(!Array.isArray(keys)) { keys = [keys] };

		keys.forEach(key => {
			eventHandler.setCustomListener('template--removed', `template--${key}`, e => {

				if(`template--${key}` != e.detail.key) { return; }

				callback({ element: e.detail.data.element });
			});
		});
	}
}

export const template = new Template();